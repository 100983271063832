import { apiURL } from '@/main'
import { app } from '@/main'
import { showError } from '@/services/axios'
import { defineStore } from 'pinia'
export const useFormStatusStore = defineStore('formStatusStore', {
  state: () => ({
    formStatus: [] as any[]
  }),
  actions: {
    async fetchFormStatus() {
      if (this.formStatus.length == 0) {
        try {
          const result = await app.axios.get(apiURL + '/formStatus')
          this.formStatus = result.data
        } catch (e) {
          showError(e)
        }
      }
    }
  }
})
